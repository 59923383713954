import SidePopup from "~/components/common/SidePopup";
import AddUniversity from "./components/AddUniversity";
import UniversitiesData from "./components/UniversitiesData";
import { useParams } from "react-router-dom";

const Universities = () => {
  const { uni_id } = useParams();
  return (
    <>
      <UniversitiesData />
      <SidePopup component={<AddUniversity />} />
    </>
  );
};

export default Universities;
