import { Autocomplete, Box, Button, Switch, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import fonts from "../../../../../../components/FormInputs/FontBox/data/_fonts.json";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { toast } from "react-toastify";
import { useFileUpload } from "@nhost/react";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_BRAND_DETAILS, UPDATE_BRAND_DETAIL } from "./graphql/Mutation";
import { URLs } from "~/config/enums";

const BrandStylingInfo = ({ brand }: any) => {
  const { brd_id } = useParams();
  const details = brand?.brd_details?.at(0);
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [footerLogo, setFooterLogo] = useState<any | null>(null);
  const [favIcon, setFavIcon] = useState<any | null>(null);
  const [heroImage, setHeroImage] = useState<any | null>(null);
  const [showPopup, setShowPopup] = useState<any>(details?.show_popup);
  const [showWhatsappConv, setShowWhatsappConv] = useState<any>(
    details?.show_consultant_whatsapp_conv
  );

  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const [updateBrandDetail] = useMutation(UPDATE_BRAND_DETAIL);
  const [insertBrandDetail] = useMutation(INSERT_BRAND_DETAILS);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };
  const handleFooterLogo = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setFooterLogo({ file });
    }
  };
  const handleFavIcon = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setFavIcon({ file });
    }
  };
  const handleHeroImage = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setHeroImage({ file });
    }
  };

  const onSubmit = async (inpData: any) => {
    // ;
    // return 0;
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["logo"] = logoUploadRes?.id || null;
    }
    if (footerLogo) {
      const logoUploadRes = await uploadLogo(footerLogo);
      inpData["footer_logo"] = logoUploadRes?.id || null;
    }
    if (favIcon) {
      const logoUploadRes = await uploadLogo(favIcon);
      inpData["fav_icon"] = logoUploadRes?.id || null;
    }
    if (heroImage) {
      const logoUploadRes = await uploadLogo(heroImage);
      inpData["hero_image"] = logoUploadRes?.id || null;
    }
    try {
      const brandDetail: any = {
        primary_color: inpData.primary_color,
        secondary_color: inpData.secondary_color,
        font: inpData.font,
        show_popup: showPopup,
        popup_time: inpData.popup_time,
        discount_in_percentage: inpData.discount_in_percentage,
        one_signal_id: inpData.one_signal_id,
        show_consultant_whatsapp_conv: inpData.show_consultant_whatsapp_conv,
        offline_dest_fares: inpData.offline_dest_fares,
        tagline: inpData.tagline,
      };
      if (inpData.logo) {
        brandDetail.logo = inpData.logo;
      }
      if (inpData.footer_logo) {
        brandDetail.footer_logo = inpData.footer_logo;
      }
      if (inpData.fav_icon) {
        brandDetail.fav_icon = inpData.fav_icon;
      }
      if (inpData.hero_image) {
        brandDetail.hero_image = inpData.hero_image;
      }
      if (details?.id) {
        const response = await updateBrandDetail({
          variables: {
            id: {
              id: details.id,
            },
            brdData: brandDetail,
          },
        });
        if (response?.data?.update_brd_detail_by_pk?.id) {
          toast.success("Brand added successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await client.refetchQueries({
            include: "all",
          });
        }
      } else {
        const response = await insertBrandDetail({
          variables: {
            data: { ...brandDetail, brd_id: brd_id },
          },
        });
        if (response?.data?.insert_brd_detail_one?.id) {
          toast.success("Brand updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await client.refetchQueries({
            include: "all",
          });
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Brand Detail Info</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <div className="flex items-center gap-3">
              <div>
                <label className="mb-2">Footer Logo</label>
                <TextField
                  sx={{ width: "100%" }}
                  // {...register("featuredImage")}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={handleFooterLogo}
                  helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
                />
              </div>
              {details?.footer_logo && (
                <img
                  style={{
                    backgroundColor: `${details?.primary_color}`,
                  }}
                  className={`w-[100px] h-[55px] p-1 rounded`}
                  src={`${URLs.FILE_URL}${details?.footer_logo}`}
                />
              )}
            </div>
            <div className="flex items-center gap-3">
              <div>
                <label className="mb-2">Brand Logo</label>
                <TextField
                  sx={{ width: "100%" }}
                  // {...register("featuredImage")}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={handleLogoUpload}
                  helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
                />
              </div>
              {details?.logo && (
                <img
                  className={`w-[100px] h-[55px] p-1 rounded`}
                  src={`${URLs.FILE_URL}${details?.logo}`}
                />
              )}
            </div>
            <div className="flex items-center gap-3">
              <div>
                <label className="mb-2">Fav Icon</label>
                <TextField
                  sx={{ width: "100%" }}
                  // {...register("featuredImage")}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={handleFavIcon}
                  helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
                />
              </div>
              {details?.fav_icon && (
                <img
                  className={`w-[100px] h-[55px]  p-1 rounded`}
                  src={`${URLs.FILE_URL}${details?.fav_icon}`}
                />
              )}
            </div>
            <div className="flex items-center gap-3">
              <div>
                <label className="mb-2">Default Hero Image</label>
                <TextField
                  sx={{ width: "100%" }}
                  // {...register("featuredImage")}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={handleHeroImage}
                  helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
                />
              </div>
              {details?.hero_image && (
                <img
                  className={`w-[100px] h-[55px] p-1 rounded`}
                  src={`${URLs.FILE_URL}${details?.hero_image}`}
                />
              )}
            </div>
            <TextField
              defaultValue={details?.tagline}
              {...register("tagline")}
              label="Tagline"
              type="text"
            />
            <TextField
              defaultValue={details?.primary_color}
              {...register("primary_color")}
              label="Primary Color"
              type="text"
            />
            <TextField
              defaultValue={details?.secondary_color}
              {...register("secondary_color")}
              label="Secondary Color"
              type="text"
            />
            <TextField
              defaultValue={details?.discount_in_percentage}
              {...register("discount_in_percentage")}
              label="Discount in Percentage"
              type="number"
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("font")}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.family);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.family
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={fonts?.items ? fonts?.items : []}
                  getOptionLabel={(option: any) => option.family}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.family}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = fonts?.items?.find(
                      (option) =>
                        option?.family?.toLowerCase() ==
                        details?.font?.toLowerCase()
                    );
                    field.onChange(data?.family);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Font"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              defaultValue={details?.one_signal_id}
              {...register("one_signal_id")}
              label="One Signal APP ID"
            />

            <div className="flex justify-between gap-5">
              <div className="flex justify-between xl:w-[60%] items-center">
                <label>Show Popup</label>
                <Switch
                  {...register("show_header_airline_name")}
                  value={showPopup}
                  checked={showPopup}
                  onChange={() => setShowPopup(!showPopup)}
                />
              </div>
              {showPopup && (
                <TextField
                  sx={{ width: "100%" }}
                  defaultValue={details?.popup_time}
                  error={errors["popup_time"] ? true : false}
                  {...register("popup_time", {
                    required: true,
                  })}
                  label="Popup Time (In Seconds)"
                  type="number"
                />
              )}
            </div>
            <div className="flex gap-20 items-center">
              <label>Show Whatsapp Conversations (Consultant)</label>
              <Switch
                {...register("show_consultant_whatsapp_conv")}
                value={showWhatsappConv}
                checked={showWhatsappConv}
                onChange={() => setShowWhatsappConv(!showWhatsappConv)}
              />
            </div>
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Brand Details
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandStylingInfo;
