import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ContentEditor from "~/components/common/ContentEditor";
import { SEND_EMAIL } from "~/graphql/actions/gmail/Mutation";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Files from "./components/Files";
import { URLs } from "~/config/enums";
import { useNhostClient, useUserData } from "@nhost/react";
import { OAuth2Client } from "google-auth-library";
import { google } from "googleapis";
import { toast } from "react-toastify";
import { emailSignatureTemp } from "~/utils/emailSignatureTemp";
import useUserDetail from "~/hooks/useUserDetail";

export default function ComposeEmail({
  user_email,
  gmail_refresh_token,
  iata,
  brd_detail,
}: any) {
  const nhost = useNhostClient();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [content, setContent] = useState<any>();

  const userData = useUserData();

  const thpUserDetail: any = useUserDetail(userData?.id);

  useEffect(() => {
    if (thpUserDetail?.users?.length > 0) {
      setContent(emailSignatureTemp(thpUserDetail, brd_detail));
    }
  }, [thpUserDetail]);

  console.log("jhsgfjsdf", thpUserDetail, brd_detail);

  const handleSendEmail = async (data: any) => {
    setLoading(true);
    const fileAttachments = files.map((file: any) => {
      return {
        name: file.name,
        url: URLs.FILE_URL + file.id,
      };
    });

    try {
      const response = await sendEmail({
        variables: {
          data: {
            toEmails: user_email,
            subject: data.subject || "Subject",
            content: content,
            from: userData?.metadata?.alias,
            refresh_token: gmail_refresh_token,
            attachments: fileAttachments,
            iata: iata || "No iata",
          },
        },
      });
      // ;
      fileAttachments.map(async (file: any) => {
        await nhost.storage.delete({ fileId: file.id });
      });
      setLoading(false);
      // setCompose(false);

      toast.success(
        "Email Sent Successfully, It will take few seconds to fetch"
      );
    } catch (error) {
      console.error("Error sending and/or refetching emails:", error);
      setLoading(false);
    }
  };

  // ;
  return (
    <div className="mt-12 mx-12">
      <form onSubmit={handleSubmit(handleSendEmail)}>
        <div className="grid grid-cols-6 gap-6 mb-4">
          <div className="col-span-6 sm:col-full ck-editor">
            <TextField
              error={errors["id"] ? true : false}
              id="outlined-basic"
              label="Subject"
              {...register("subject", { required: true })}
              variant="outlined"
              type="text"
              className="w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-full ck-editor">
            <ContentEditor content={content} setContent={setContent} />
          </div>
          <div className="col-span-6 sm:col-full ck-editor">
            <Files setFilesId={setFiles} />
          </div>
          <div className="col-span-6 sm:col-full">
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
            >
              <span>Send</span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}
