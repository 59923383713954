import React, { useState } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import useDefUniCampusesData from "~/hooks/useDefUniCampusesData";
import { DELETE_UNIVERSITY_CAMPUS_WITH_ID } from "~/graphql/def_university_campuses/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditCampus from "../EditCampus";

const CampusesData = ({ uniId }: any) => {

    const { uniCampusesData } = useDefUniCampusesData(uniId);
    const [selectedCampus, setSelectedCampus] = useState<any>(null);
    const [openPopup, setOpenPopup] = useState(false);
    const client = useApolloClient()
    const [DeleteUniCampus] = useMutation(DELETE_UNIVERSITY_CAMPUS_WITH_ID)

    const handleDeleteUniversityCampus = async (campus_id: any) => {
        const res = await DeleteUniCampus({
            variables: {
                campusId: campus_id,
            }
        })

        if (res?.data?.delete_def_university_campuses?.affected_rows > 0) {
            toast.success("Campus Deleted Successfully!")
            client.refetchQueries({
                include: "all"
            })
        }
    }

    const handleEditUniversityCampus = (campus: any) => {
        setSelectedCampus(campus);
        setOpenPopup(true);
    }

    return (
        <div className="p-4">
            <h1 className="text-xl font-semibold mb-4">{uniCampusesData?.def_universities?.[0]?.name}</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Campus Name</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {uniCampusesData?.def_universities?.[0]?.def_university_campuses?.map((campus: any) => (
                            <TableRow key={campus.id}>
                                <TableCell>{campus.name}</TableCell>
                                <TableCell>{campus.city}</TableCell>
                                <TableCell>{campus.country}</TableCell>
                                <TableCell>
                                    <div className="flex space-x-5">
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                handleEditUniversityCampus(campus);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="error"
                                            onClick={() => {
                                                handleDeleteUniversityCampus(campus.id);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {openPopup && (
                <SideDrawer
                    component={
                        <EditCampus
                            selectedCampus={selectedCampus}
                            setSidePopup={setOpenPopup}
                        />
                    }
                    sidePopup={openPopup}
                    setSidePopup={setOpenPopup}
                />
            )}
        </div>
    );
};

export default CampusesData;
