import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_ADMISSION_REQUIREMENTS_WITH_ID } from "~/graphql/def_admission_requirements/Mutation";

const EditAdmissionRequirementsData = ({
  selectedQualification,
  setSidePopup,
}: any) => {
  const { register, handleSubmit }: any = useForm<FormData>();
  const [editRequirements] = useMutation(UPDATE_ADMISSION_REQUIREMENTS_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await editRequirements({
      variables: {
        id: selectedQualification.id, // Include the ID here
        data: {
          gap_allowed: formData.gap_allowed, // Map the fields from formData
          grades: formData.grades,
          qualification: formData.qualification,
          scholarship_available: formData.scholarship_available,
        },
      },
    });
    if (res?.data?.update_def_admission_requirements?.affected_rows > 0) {
      toast.success("Admission Requirements Updated Successfully");
      client.refetchQueries({
        include: "active",
      });
      setSidePopup(false);
    }
  };
  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Admission Requirements
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Qualification"
          variant="outlined"
          required
          fullWidth
          {...register("qualification")}
          defaultValue={selectedQualification?.qualification}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Grades"
          variant="outlined"
          fullWidth
          required
          {...register("grades")}
          defaultValue={selectedQualification?.grades}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Gap Allowed"
          variant="outlined"
          fullWidth
          required
          {...register("gap_allowed")}
          defaultValue={selectedQualification?.gap_allowed}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Scholarship Available"
          variant="outlined"
          required
          defaultValue={selectedQualification?.scholarship_available}
          fullWidth
          {...register("scholarship_available")}
          style={{ marginBottom: "16px" }}
        />

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditAdmissionRequirementsData;
