import SidePopup from "~/components/common/SidePopup";
import AddAdmissionRequirementsData from "./components/AddAdmissionRequirementsData";
import AdmissionRequirementData from "./components/AdmissionRequirementData";
import { useParams } from "react-router-dom";

const AdmissionRequirements = () => {
  const { course_id } = useParams();
  return (
    <>
      <AdmissionRequirementData courseId={course_id} />
      <SidePopup
        component={<AddAdmissionRequirementsData courseId={course_id} />}
      />
    </>
  );
};

export default AdmissionRequirements;
