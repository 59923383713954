import { GET_CAMPUSES_WITH_UNIVERSITY_ID } from "~/graphql/def_university_campuses/Query";
import { useQuery } from "@apollo/client";

export default function useDefUniCampusesData(uni_id: any) {
  const { data } = useQuery(GET_CAMPUSES_WITH_UNIVERSITY_ID, {
    variables: {
        universityId: uni_id,
    }
  });

  return { uniCampusesData: data };
}