import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import SideDrawer from "~/components/common/sideDrawer";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { DELETE_UNIVERSITY_COURSE_WITH_ID } from "~/graphql/def_university_courses/Mutation";
import EditCourse from "../EditCourse";
import { useNavigate, useParams } from "react-router-dom";
import useDefUniCoursesData from "~/hooks/useDefUniCoursesData";

const CoursesData = ({ uniId }: any) => {

  const { uniCoursesData } = useDefUniCoursesData(uniId);

  const navigate = useNavigate();

  const { brd_id } = useParams();

  const client = useApolloClient();
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [deleteUniversityCourse] = useMutation(
    DELETE_UNIVERSITY_COURSE_WITH_ID
  );

  const handleDeleteUniversityCourse = async (id: string) => {
    const res = await deleteUniversityCourse({
      variables: {
        id,
      },
    });
    if (res?.data?.delete_def_university_courses?.affected_rows > 0) {
      toast.success("Course Deleted Successfully!");
      client.refetchQueries({
        include: "all",
      });
    }
  };
  const handleEditUniversityCourse = (course: any) => {
    setSelectedCourse(course);
    setOpenPopup(true); // Open the popup
  };
  const handleViewRequirements = (course_id: any) => {
    navigate(
      `/admin/brands/${brd_id}/universities/courses/${course_id}/admission_requirement`
    );
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Course Name</TableCell>
              <TableCell>Tuition Fees</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniCoursesData?.def_university_courses?.map((course: any) => (
              <TableRow key={course.id}>
                <TableCell>{course.name}</TableCell>
                <TableCell>{course.tuition_fees}</TableCell>
                <TableCell>
                  <div className="flex space-x-5">
                    <Button
                      color="primary"
                      onClick={() => {
                        handleEditUniversityCourse(course);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="error"
                      onClick={() => {
                        handleDeleteUniversityCourse(course.id);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      color="info"
                      onClick={() => handleViewRequirements(course.id)}
                    >
                      View Admission Requirements
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditCourse
              selectedCourse={selectedCourse}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default CoursesData;
