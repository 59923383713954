import { gql } from "@apollo/client";

export const GET_UNIVERSITIES = gql`
  query GetUniversitiesWithCampuses {
    def_universities {
      id
      name
      def_university_campuses {
        name
      }
    }
  }
`;
