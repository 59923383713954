import SidePopup from "~/components/common/SidePopup";
import AddCourse from "./components/AddCourse";
import CoursesData from "./components/CoursesData";
import { useParams } from "react-router-dom";

const Courses = () => {

  const { uni_id } = useParams();
  return (
    <>
      <CoursesData uniId={uni_id} />
      <SidePopup component={<AddCourse uniId={uni_id} />} />
    </>
  );
};

export default Courses;
