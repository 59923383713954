import { gql } from "@apollo/client";

export const GET_ADMISSION_REQUIREMENTS = gql`
  query GetAdmissionRequirements($def_university_course_id: uuid!) {
    def_admission_requirements(
      where: { def_university_course_id: { _eq: $def_university_course_id } }
    ) {
      qualification
      grades
      gap_allowed
      scholarship_available
      id
      def_university_course_id
    }
  }
`;
