import { gql } from "@apollo/client";

export const INSERT_UNIVERSITY_CAMPUS = gql`
  mutation InsertUniCampus(
    $universityId: uuid!
    $campusName: String!
    $city: String!
    $country: String!
  ) {
    insert_def_university_campuses(
      objects: {
        def_university_id: $universityId
        name: $campusName
        city: $city
        country: $country
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_CAMPUS_WITH_ID = gql`
  mutation DeleteUniCampus($campusId: uuid!) {
    delete_def_university_campuses(where: {id: {_eq: $campusId}}) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_CAMPUS_WITH_ID = gql`
  mutation UpdateUniCampus(
    $campusId: uuid!,
    $name: String!,
    $country: String!,
    $city: String!
  ) {
    update_def_university_campuses(
      where: { id: { _eq: $campusId } },
      _set: { name: $name, country: $country, city: $city }
    ) {
      affected_rows
    }
  }
`;

