import SidePopup from "~/components/common/SidePopup";
import AddCampus from "./components/AddCampus";
import { useParams } from "react-router-dom";
import CampusesData from "./components/CampusesData";

const Campuses = () => {
  const { uni_id } = useParams();

  return (
    <>
      <CampusesData uniId={uni_id} />
      <SidePopup component={<AddCampus uniId={uni_id} />} />
    </>
  );
};

export default Campuses;
