import { useQuery } from "@apollo/client";
import { GET_UNIVERSITY_COURSES } from "~/graphql/def_university_courses/Query";

export default function useDefUniCoursesData(uni_id: any) {
  const { data } = useQuery(GET_UNIVERSITY_COURSES, {
    variables: {
      universityId: uni_id,
    },
  });

  return { uniCoursesData: data };
}
