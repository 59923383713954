import { TextField, Button, Chip } from "@mui/material";

import { useForm } from "react-hook-form";

import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_UNIVERSITY_COURSE_WITH_ID } from "~/graphql/def_university_courses/Mutation";

const EditCourse = ({ selectedCourse, setSidePopup }: any) => {
  const { register, handleSubmit, watch, reset }: any = useForm<FormData>();
  const [editCourses] = useMutation(UPDATE_UNIVERSITY_COURSE_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await editCourses({
      variables: {
        id: selectedCourse.id, // Make sure to include the ID here
        data: {
          name: formData.course,
          tuition_fees: formData.fees,
        },
      },
    });
    if (res?.data?.update_def_university_courses?.affected_rows > 0) {
      toast.success("Course Updated Successfully");
      client.refetchQueries({
        include: "active",
      });
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Courses Data
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Course Name"
          variant="outlined"
          fullWidth
          required
          {...register("course")}
          defaultValue={selectedCourse?.name}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Tuition Fees"
          variant="outlined"
          fullWidth
          required
          {...register("fees")}
          defaultValue={selectedCourse?.tuition_fees}
          style={{ marginBottom: "16px" }}
        />

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditCourse;
