import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { INSERT_ADMISSION_REQUIREMENTS } from "~/graphql/def_admission_requirements/Mutation";

interface FormData {
  university: string;
  qualification: string;
  grades: string;
  gap_allowed: boolean;
  scholarship_available: boolean;
}

const AddAdmissionRequirementsData = ({ courseId, setSidePopup }: any) => {
  console.log("course_id:", courseId);
  // const { course_id } = useParams();
  const client = useApolloClient();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [insertRequirements] = useMutation(INSERT_ADMISSION_REQUIREMENTS);
  const onSubmit = async (data: FormData) => {
    const result = await insertRequirements({
      variables: {
        data: [
          {
            qualification: data.qualification,
            grades: data.grades,
            gap_allowed: data.gap_allowed,
            scholarship_available: data.scholarship_available,
            def_university_course_id: courseId,
          },
        ],
      },
    });
    if (result.data.insert_def_admission_requirements.affected_rows > 0) {
      client.refetchQueries({
        include: "all",
      });
      toast.success("University Added Successfully!");
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Admission Requirements
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Qualification"
          variant="outlined"
          fullWidth
          required
          {...register("qualification")}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Grades"
          variant="outlined"
          fullWidth
          required
          {...register("grades")}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Gap Allowed"
          variant="outlined"
          required
          fullWidth
          {...register("gap_allowed")}
          style={{ marginBottom: "16px" }}
        />

        <TextField
          label="Scholarship Available"
          variant="outlined"
          required
          fullWidth
          {...register("scholarship_available")}
          style={{ marginBottom: "16px" }}
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddAdmissionRequirementsData;
