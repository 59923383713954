import { gql } from "@apollo/client";

export const GET_CAMPUSES_WITH_UNIVERSITY_ID = gql`
  query GetCampusesWithUniId($universityId: uuid!) {
    def_universities(where: {id: {_eq: $universityId}}) {
      name
      def_university_campuses {
        id
        name
        city
        country
      }
    }
  }
`;
