import { gql } from "@apollo/client";

export const GET_UNIVERSITY_COURSES = gql`
  query getUniversityCourses($universityId: uuid!) {
    def_university_courses(
      where: { def_university_id: { _eq: $universityId } }
    ) {
      name
      def_university_id
      id
      tuition_fees
    }
  }
`;
